<template>
    <div>
        <DataTableComponent
            :items="listado_actividades"
            :headers="headers"
            :total_registros="total_rows"
            :show_loading="isLoading"
            :show_actions="false"
            :actions="[]"
            @paginar="paginar"
            dense
            no-gutters
        >
            <template v-slot:[`item.catalogo`]={item}>
                <v-chip :color="item?.a?.ae?.color"> {{ item?.a?.ae?.nombre }}</v-chip>
            </template>
        </DataTableComponent>
        <div class="d-flex justify-end mt-4">
            <ObservarProveedorComponent />
        </div>
    </div>
</template>
<script>
import ObservarProveedorComponent from '@/views/Rupes/components/verificacionProveedor/ObservarProveedorComponent.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import ActividadesServices from "@/services/Actividad.services";

export default {
    name: 'ListadoActividadesProveedor',
    components: { DataTableComponent, ObservarProveedorComponent },
    props: {
        idProveedor: { type: [Number, String] },
    },
    data: () => ({
        total_rows: 0,
        isLoading: false,
        listado_actividades: [],
        paginacion: {
            page: 1,
            per_page: 10,
        },
    }),
    computed: {
        headers() {
            return [
                { text: 'Acctividad', value: 'a.nombre', align: 'start' },
                { text: 'Catalogo', value: 'catalogo', align: 'center' },
            ];
        },
    },
    methods: {
        async getActividades() {
            this.isLoading = true;
            const {data, headers} = await ActividadesServices.getActividadesByProv(this.idProveedor, this.paginacion);
            this.isLoading = false;
            this.paginacion.page = Number(headers.page)
            this.total_rows = Number(headers.total_rows)
            this.paginacion.per_page = Number(headers.per_page)
            this.listado_actividades = data;
        },
        paginar({cantidad_por_pagina, pagina}) {
            this.paginacion.page = pagina;
            this.paginacion.per_page = cantidad_por_pagina;
            this.getActividades();
        },
    },
    created() {
        this.getActividades();
    },
}
</script>
