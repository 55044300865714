<template>
  <v-card>
    <v-card-title style="gap: 8px;">
        <v-btn @click="$emit('closeModal')" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h6 class="text-h6 font-weight-bold text-uppercase">Verificación de proveedor</h6>
    </v-card-title>
      <v-divider />
    <v-card-text>
      <v-stepper v-model="step" flat>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">Actividades</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">Documentación</v-stepper-step>
          <v-divider></v-divider>

          <template v-if="!esProveedorNatural">
            <v-stepper-step :complete="step > 3" step="3">Accionistas</v-stepper-step>
            <v-divider></v-divider>
          </template>

          <v-stepper-step step="4">Contacto</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <ListadoActividadesProveedor :id-proveedor="idProveedor" />

            <v-btn text @click="$emit('closeModal')">Cancelar</v-btn>
            <v-btn class="mx-2" color="secondary" @click="step = 2">Siguiente</v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <DocumentacionVerificacionComponent />

            <v-btn text @click="step = 1">Anterior</v-btn>
            <v-btn class="mx-2" color="secondary" @click="step = esProveedorNatural ? 4 : 3">Siguiente</v-btn>
          </v-stepper-content>

          <v-stepper-content v-if="!esProveedorNatural" step="3">
            <AccionistasVerificacionComponent />

            <v-btn text @click="step = 2">Anterior</v-btn>
            <v-btn class="mx-2" color="secondary" @click="step = 4">Siguiente</v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <ContactoVerificacionComponent />

            <v-btn text @click="step = esProveedorNatural ? 2 : 3">Anterior</v-btn>
            <v-btn dark color="success" class="mx-2" @click="sendVerificacion()">Verificar proveedor</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <v-dialog v-model="showModalVerificado" max-width="800" persistent>
        <v-card>
          <v-card-text class="py-4">
            <p class="text-h5 secondary--text text-center">
              Proveedor verificado con éxito
            </p>
            <div class="d-flex justify-center">
              <v-icon size="80" color="yellow">mdi-check-decagram</v-icon>
            </div>
            <div class="d-flex justify-center my-4">
              <v-btn dark color="secondary" @click="closeModal()"
                >Continuar</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
import OBSVerificacionComponent from "./OBSVerificacionComponent.vue";
import GiroEmpresarialVerificacionComponent from "./GiroEmpresarialVerificacionComponent.vue";
import DocumentacionVerificacionComponent from "./DocumentacionVerificacionComponent.vue";
import AccionistasVerificacionComponent from "./AccionistasVerificacionComponent.vue";
import ContactoVerificacionComponent from "./ContactoVerificacionComponent.vue";
import ListadoActividadesProveedor from "@/views/PerfilProveedor/components/ListadoActividadesProveedor.vue";

export default {
  name: "MainVerificacionProveedorComponent",
  components: {
    OBSVerificacionComponent,
    GiroEmpresarialVerificacionComponent,
    DocumentacionVerificacionComponent,
    AccionistasVerificacionComponent,
    ContactoVerificacionComponent,
    ListadoActividadesProveedor,
  },
  props: {
    tipoContribuyente: { type: [Number, String] },
    idProveedor: { type: [Number, String] },
  },
  data: () => ({
    step: 1,
    showModalVerificado: false,
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
    esProveedorNatural() {
      return Number(this.tipoContribuyente) === 1;
    },
  },
  methods: {
    async sendVerificacion() {
      const { status } = await this.services.Proveedores.verificarProveedor(
        this.provider.id,
        {
          id_estado_verificacion: 1,
        }
      );

      if (status == 200 || status == 204) {
        this.showModalVerificado = true;
      }
    },
    closeModal() {
      location.reload();
    },
  },
};
</script>
