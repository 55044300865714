<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="d-flex" style="gap: 8px;">
          <NavButton />
          <p class="mb-0">
            <span
                class="d-inline-block font-weight-bold text-uppercase text-h5 mr-2"
                style="transform: translateY(3px); word-break: break-word; "
            >
              {{ idProveedor && provider.nombre ? `Rupes / ${provider.nombre}` : "Perfil de proveedor" }}
            </span>

            <v-tooltip v-if="tooltipPerfil" :color="tooltipPerfil.color" top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip v-if="tooltipPerfil.porcentaje" v-on="on" v-bind="attrs">
                  {{ tooltipPerfil.porcentaje }}%
                </v-chip>
                <v-icon  v-if="tooltipPerfil.icono" v-on="on" v-bind="attrs" :color="tooltipPerfil.color" size="30">
                  {{ tooltipPerfil.icono }}
                </v-icon>
              </template>
              <span>{{ tooltipPerfil.titulo }}</span>
            </v-tooltip>
          </p>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <!-- Información de perfil -->
        <InformacionPerfil
            :perfilCompleto="perfilCompleto"
            :progresoPerfil="progresoPerfil"
            :idProveedor="idProveedor"
            color-btn-edicion="secondary"
            :es-proveedor="false"
            :solo-lectura="!haveRoles(['ROLE_DIRECCION_COMPRAS'])"
            @on-save="cargarInformacionProveedor"
        >
          <template v-slot:acciones>
            <v-btn
                v-if="haveRole('ROLE_RUPES_ALERTA_CREATE')"
                @click="modalCrearAlertaAbierta = true"
                color="secondary"
            >
              <v-icon color="white" class="mr-2">mdi-comment-alert</v-icon>
              Crear alerta
            </v-btn>
            <v-btn
                v-if="!proveedorVacio && provider.id_estado_verificacion !== 1 && haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_CALLCENTER'])"
                color="secondary"
                class="white--text"
                dark
                @click="modalVerificacionAbierta = true"
            >
              <v-icon color="white" class="mr-2">mdi-check-decagram</v-icon>
              Verificar
            </v-btn>
            
            <v-btn v-if="haveRole('ROLE_DETALLE_REGISTRO_RUPES')" color="secondary" @click="mostrarDetalleSolicitudRegistro = true">
              <v-icon color="white" class="mr-2">mdi-calendar-text-outline</v-icon>
              Solicitud de registro
            </v-btn>
          </template>
        </InformacionPerfil>

        <!-- Opciones del menu de usuario -->
        <AccionesPerfil class="mt-4" :proveedor="provider" :es-proveedor="false">
          <template v-slot:item.resena="{ item }">
            <div
                class="text-decoration-none px-4 py-4 d-flex flex-column align-center item-container mx-auto"
                style="cursor: pointer;"
                @click.stop="modalResenaAbierta = true"
            >
              <v-icon
                  color="primary"
                  size="75px"
                  class="btns"
              >
                {{ item.icon }}
              </v-icon>
              <p class="text-center mt-4 mb-0"> {{ item.label }} </p>
            </div>
          </template>
          <template v-slot:item.actividades="{ item }">
            <ActividadProveedor
                :label="item.label"
                :icon="item.icon"
                :id_proveedor="provider?.id"
                @update:recargar="recargar"
            />
          </template>
        </AccionesPerfil>
      </v-card-text>
    </v-card>

    <ModalReseñaComponent
        :showModal="modalResenaAbierta"
        @close="modalResenaAbierta = false"
    />

    <v-dialog v-model="modalVerificacionAbierta" max-width="1500" persistent>
      <MainVerificacionProveedorComponent 
        :tipo-contribuyente="tipoContribuyente"
        :id-proveedor="provider?.id"
        @closeModal="modalVerificacionAbierta = false" 
      />
    </v-dialog>

    <v-dialog v-model="modalCrearAlertaAbierta" max-width="600" persistent>
      <v-card>
        <v-card-title class="align-center px-4 py-4" style="gap: 8px;">
          <v-btn icon @click="modalCrearAlertaAbierta = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <p class="text-h6 text-uppercase font-weight-bold mb-0">Agregar alerta</p>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 py-4">
          <AppRichTextComponent
              v-model="textoAlerta"
              placeholder="Escriba una alerta para el proveedor aquí"
              @input="touchTextAlert"
          />
          <v-fade-transition>
            <span
                v-show="erroresCrearAlerta"
                class="ml-1 error--text"
                v-text="erroresCrearAlerta"
            />
          </v-fade-transition>
          <div class="d-flex justify-center align-center flex-wrap flex-md-nowrap mt-4" style="gap: 16px;">
            <v-btn
                class="flex-grow-1 flex-shrink-1"
                color="secondary"
                @click="modalCrearAlertaAbierta = false"
                large
            >
              Cancelar
            </v-btn>
            <v-btn
                class="flex-grow-1 flex-shrink-1"
                color="primary"
                large
                @click="crearAlertaProveedor"
            >
              Registrar
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <SolicitudRegistroComponent
      v-if="haveRole('ROLE_DETALLE_REGISTRO_RUPES')"
      :show="mostrarDetalleSolicitudRegistro"
      @update-show="mostrarDetalleSolicitudRegistro = $event"
    />
  </v-container>
</template>
<script>
import PerfilItem from "../PerfilProveedor/components/PerfilItemComponent.vue";
import ModuloProveedor from "./components/moduloProveedorComponent.vue";
import ReseñaProveedorComponent from "./components/reseñaProveedorComponent.vue";
import moduloSancionComponent from "./components/moduloSancionComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import MainVerificacionProveedorComponent from "./components/verificacionProveedor/MainVerificacionProveedorComponent.vue";
import NavButton from "@/components/utils/NavButton.vue";
import InformacionPerfil from "../PerfilProveedor/components/Perfil/InformacionPerfil.vue";
import AccionesPerfil from "../PerfilProveedor/components/Perfil/AccionesPerfil.vue";
import ModalReseñaComponent from "./components/modalReseñaComponent.vue";
import AppRichTextComponent from "@/components/AppRichTextComponent.vue";
import ActividadProveedor from "../PerfilProveedor/components/ActividadProveedor.vue";
import SolicitudRegistroComponent from "./components/SolicitudRegistroComponent.vue";

export default {
  name: "proveedorView",
  components: {
    ModuloProveedor,
    PerfilItem,
    ReseñaProveedorComponent,
    MainVerificacionProveedorComponent,
    moduloSancionComponent,
    NavButton,
    InformacionPerfil,
    AccionesPerfil,
    ModalReseñaComponent,
    AppRichTextComponent,
    ActividadProveedor,
    SolicitudRegistroComponent,
  },
  data: () => ({
    progresoPerfil: null,
    perfilCompleto: null,
    textoAlerta: "<p></p>",
    erroresCrearAlerta: null,
    modalResenaAbierta: false,
    modalVerificacionAbierta: false,
    modalCrearAlertaAbierta: false,
    mostrarDetalleSolicitudRegistro: false,
  }),
  computed: {
    ...mapState("proveedores", ["provider"]),
    idProveedor() {
      return this.$route.params.id_proveedor;
    },
    proveedorVacio() {
      return Object.keys(this.provider).length === 0;
    },
    tooltipPerfil() {
      if (!this.progresoPerfil) return null;

      if (!this.esUsuarioDINAC) {
        if (this.progresoPerfil === 100) {
          return ({
            color: 'cyanDinac',
            icono: 'mdi-check-decagram',
            titulo: 'Perfil verificado',
          });
        } else {
          return ({
            porcentaje:  Number(this.progresoPerfil).toFixed(0),
            titulo: 'Porcentaje de avance',
          });
        }
      }

      if (this.progresoPerfil === 100 && this.provider?.id_estado_verificacion === 1) {
        return ({
          color: 'cyanDinac',
          icono: 'mdi-check-decagram',
          titulo: 'Perfil verificado',
        });
      }

      if (this.esUsuarioDINAC && this.progresoPerfil === 100 && this.provider?.id_estado_verificacion === 2) {
        return ({
          color: 'conObservacion',
          icono: 'mdi-account-eye',
          titulo: 'Perfil con observación',
        });
      }

      if (this.esUsuarioDINAC && this.progresoPerfil === 100 && (this.provider?.id_estado_verificacion === 3 || this.provider?.id_estado_verificacion === null)) {
        return ({
          color: 'pedienteVerificar',
          icono: 'mdi-account-clock',
          titulo: 'Perfil completo pendiente de verificar',
        });
      }

      if (this.progresoPerfil < 100) {
        return ({
          porcentaje:  Number(this.progresoPerfil).toFixed(0),
          titulo: 'Porcentaje de avance',
        });
      }

      return null;
    },
    tipoContribuyente() {
      return this.provider.identificacion_tributaria?.id_tipo_contribuyente;
    },
    esUsuarioDINAC() {
      return this.haveRoles(['ROLE_USUARIO_DINAC']);
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProvider"]),
    ...mapMutations("proveedores", ["setProviderInfo"]),
    touchTextAlert() {
      this.erroresCrearAlerta = this.textoAlerta == "<p></p>" ? "Por favor ingrese un valor en el campo" : null;
    },
    async getProfilePercentage() {
      if (this.$route.params.id_proveedor) {
        const response = await this.services.Proveedores.getProviderPercentage(Number(this.$route.params.id_proveedor));

        if (response.status == 200) {
          this.progresoPerfil = response?.data.progreso ?? 0;
          this.perfilCompleto = response?.data.perfil_completo;
        }
      }
    },
    async crearAlertaProveedor() {
      this.touchTextAlert();

      if (this.erroresCrearAlerta) return;

      const response = await this.services.Proveedores.addAlertProveedor({
        id_proveedor: Number(this.provider?.id),
        alerta: this.textoAlerta,
      });

      if (response.status == 200) {
        this.modalCrearAlertaAbierta = false;
        this.textoAlerta = '<p></p>';
        this.erroresCrearAlerta = null;
        this.pushAppMessage({ type: "success", message: response?.data?.message });
      }
    },
    cargarInformacionProveedor() {
      this.getProvider(Number(this.$route.params.id_proveedor));
    },
    recargar() {
      this.cargarInformacionProveedor();
      this.getProfilePercentage();
    }
  },
  watch: {
    modalCrearAlertaAbierta(value) {
      if (!value) {
        this.textoAlerta = "<p></p>";
        this.erroresCrearAlerta = null;
      }
    },
  },
  async created() {
    this.cargarInformacionProveedor();
    this.getProfilePercentage();
  },
  beforeDestroy() {
    this.setProviderInfo({});
  },
};
</script>

<style scoped>
.image-input {
  max-width: 500px;
}

.text-title {
  font-size: 12px;
  margin: 0;
}

.text-info {
  font-size: 16px;
  margin: 0;
}

.espace {
  padding-bottom: 10px;
}

.input-space {
  padding-top: 50px;
}
</style>