<template>
  <v-dialog v-model="showModal" max-width="85vw" @click:outside="closeModal">
    <v-card>
      <v-card-title class="align-center px-4 py-4" style="gap: 8px;">
        <v-btn icon @click.stop="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        {{ haveRole('ROLE_SUPERVISOR') ? '' : 'RUPES /' }} {{ provider.nombre }} / Reseñas
      </v-card-title>
      <v-divider />
      <v-card-text class="px-4 py-4">
        <p class="mb-0 text-body-2 font-weight-medium text-uppercase">Ponderación</p>
        <v-rating
          v-model="ponderacionReseña"
          half-increments
          size="35"
          background-color="secondary"
          color="secondary"
        />

        <v-textarea
          v-model="evaluacion"
          label="Reseña del proveedor (opcional)"
          placeholder="Reseña"
          class="mt-4"
          outlined
        />

        <v-btn color="primary" large @click="guardarEvaluacion()">
          Crear reseña
        </v-btn>

        <div class="d-flex align-center mt-12" style="gap: 8px;">
          <v-icon color="secondary">mdi mdi-history</v-icon>
          <p class="text-subtitle-1 font-weight-bold text-uppercase mb-0 secondary--text">Otras reseñas</p>
        </div>
        <v-divider class="mt-2" />

        <DataTableComponent 
          :items="reseñas"
          :headers="headers"
          :total_registros="filters.total_rows"
          class="mt-4"
          no-gutters
          dense
          v-models:pagina="filters.page"
          v-models:select="filters.per_page"
          @paginar="manejarPaginacionRegistros"
        >
        <template v-slot:item.evaluacion="{ item }">
          {{ item.evaluacion ?? '-' }}
        </template>
        <template v-slot:item.creador="{ item }">
          {{ item.institucion }}
        </template>

        <template v-slot:item.created_at="{ item }">
          <span>{{ formatDate(item.created_at) }}</span>
          <span class="ml-2 font-weight-bold">({{ moment(item.created_at).fromNow() }})</span>
        </template>

        <template v-slot:item.ponderacion="{ item }">
          <v-rating
            half-increments
            background-color="primary"
            color="primary"
            :value="Number(item.ponderacion)"
            readonly
            size="35"
          />
        </template>
        </DataTableComponent>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { formatDate } from "@/utils/datetime";

export default {
  props: {
    showModal: {
      typeof: Boolean,
    },
    idProveedor: {
      typeof: Number,
    },
  },
  components: { DataTableComponent },
  data: () => ({
    reseñas: [],
    ponderacionReseña: null,
    evaluacion: null,
    filters: {
      page: 1,
      per_page: 10,
      total_rows: null,
      pagination: "true",
    },
  }),
  validations: {
    ponderacionReseña: { required },
  },
  computed: {
    ...mapState("proveedores", ["provider"]),
    ponderacionError1() {
      const errors = [];
      if (!this.$v.ponderacionReseña.$dirty) return errors;

      !this.$v.ponderacionReseña.required && errors.push("Ponderación es requerido");

      return errors;
    },
    headers() {
      return [
        { sortable: false, align: 'center', text: 'Creador por', value: 'creador' },
        { sortable: false, align: 'center', text: 'Fecha de creación', value: 'created_at' },
        { sortable: false, align: 'center', text: 'Reseña', value: 'evaluacion' },
        { sortable: false, align: 'center', text: 'Ponderación', value: 'ponderacion' },
      ];
    },
    items() {
      return this.reseñas;
    },
  },
  methods: {
    ...mapActions("proveedores", ["getProvider"]),
    formatDate,
    closeModal() {
      this.$emit("close");
    },
    async getEvaluacionesProveedores() {
      const filtros = {
        pagination: true,
        page: this.filters.page,
        per_page: this.filters.per_page,
      };
      const { data, headers } = await this.services.ProveedorEvaluacion.getEvaluacionProveedor(this.provider.id, filtros);
      this.reseñas = data;
      this.filters.total_rows = Number(headers.total_rows);
    },
    async guardarEvaluacion() {
      if (this.ponderacionReseña === null){
        this.pushAppMessage({ type:"error", message:"Debe de elegir una ponderación" });
        return;
      } 

      let eva = {};
      eva.id_proveedor = this.provider.id;
      eva.id_contrato = null;
      eva.ponderacion = this.ponderacionReseña;
      eva.evaluacion = this.evaluacion;

        const response = await this.services.ProveedorEvaluacion.postEvaluacionProveedor(eva);

        if (response.status === 201) {
          this.pushAppMessage({  type: "success", message: "Evaluación de proveedor creada" });

          this.ponderacionReseña = null;
          this.evaluacion = null;
          this.getEvaluacionesProveedores();
        }
    },

    manejarPaginacionRegistros(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.filters.page = pagina;
      this.filters.per_page = cantidad_por_pagina;
      this.getEvaluacionesProveedores();
    },
  },
  watch: {
    'provider.id': {
      immediate: true,
      handler(value)  {
        if (!value) return;

        this.getEvaluacionesProveedores();
      },
    },
  },
};
</script>

<style></style>
